#co2map {
  margin: auto;
}
.land {
  fill: #d3d3d3;
}

.sea {
  fill: #a9a9a9;
  stroke: black;
  stroke-width: 2;
}

.border {
  fill: none;
  stroke: #fff;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.interconnect {
  fill: none;
  stroke: #777;
  stroke-dasharray: 10, 10;
  stroke-width: 4;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.bubble {
  fill-opacity: 0.5;
  stroke: #fff;
  stroke-width: 0.5px;
}

text.label {
  text-anchor: middle;
  font: 14px Helvetica;
}
text.label-small {
  text-anchor: middle;
  font: 12px Helvetica;
}
.legend circle {
  fill: none;
  stroke: #777;
}
.legend text {
  fill: #333;
  font: 14px Helvetica;
  text-anchor: middle;
}
.legend .legtitle {
  fill: #000;
  font-weight: bold;
}
.graphtitle text {
  fill: #333;
  font: 18px Helvetica;
  text-anchor: middle;
  font-weight: bold;
}
.graphsubtitle text {
  fill: #666;
  font: 16px Helvetica;
  text-anchor: middle;
  font-weight: bold;
}

.watermark text {
  text-anchor: end;
  font: 12px Helvetica;
}
