.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  background-color: #f2f1ed;
}
h1,
h2,
h3 {
  margin: 0px;
  padding-top: 5px;
  color: #2e2d29;
  font-weight: 300;
}
h1 {
  text-align: center;
}
#page_subtitle {
  text-align: center;
}
#last_updated {
  font-style: italic;
}
ol {
  counter-reset: list;
  margin-left: 0px;
  padding-left: 0px;
}
ol > li {
  list-style: none;
}
ol > li:before {
  content: "[" counter(list) "] ";
  counter-increment: list;
}

.active {
  font-weight: bold;
  border-bottom: 4px solid #2e2d29;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  padding: 0;
  margin: 10px;
}

ul.header li a {
  color: #2e2d29;
  text-decoration: none;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
}
div.banner {
  margin: 0px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
}
